// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BaseLayout_topBar__0T7As{
    position: fixed;
    z-index: 10;
    top: 0;
}

.BaseLayout_sideBar__ATLOf{
    position: fixed;
    z-index: 10;
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .BaseLayout_sideBar__ATLOf{
        position: sticky;
    }
}
    `, "",{"version":3,"sources":["webpack://./src/components/BaseLayout/BaseLayout.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,MAAM;AACV;;AAEA;IACI,eAAe;IACf,WAAW;IACX,aAAa;AACjB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".topBar{\n    position: fixed;\n    z-index: 10;\n    top: 0;\n}\n\n.sideBar{\n    position: fixed;\n    z-index: 10;\n    height: 100vh;\n}\n\n@media screen and (max-width: 768px) {\n    .sideBar{\n        position: sticky;\n    }\n}\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBar": `BaseLayout_topBar__0T7As`,
	"sideBar": `BaseLayout_sideBar__ATLOf`
};
export default ___CSS_LOADER_EXPORT___;
